@tailwind base;
@tailwind components;
@tailwind utilities;

.ag-details-row {
  padding: 10px !important;
}

.ag-tool-panel-wrapper .ag-react-container {
  width: 100%;
}